exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contador-js": () => import("./../../../src/pages/contador.js" /* webpackChunkName: "component---src-pages-contador-js" */),
  "component---src-pages-currently-playing-js": () => import("./../../../src/pages/currently-playing.js" /* webpackChunkName: "component---src-pages-currently-playing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-top-artist-js": () => import("./../../../src/pages/top-artist.js" /* webpackChunkName: "component---src-pages-top-artist-js" */),
  "component---src-pages-top-genres-js": () => import("./../../../src/pages/top-genres.js" /* webpackChunkName: "component---src-pages-top-genres-js" */),
  "component---src-pages-top-tracks-js": () => import("./../../../src/pages/top-tracks.js" /* webpackChunkName: "component---src-pages-top-tracks-js" */)
}

